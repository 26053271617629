import React from "react";

export default function Header({title='Pricing',large=true,description_size="text-xl",title_size="text-xl",bg_color="bg-white",subtitle='',description=''}) {

    return <div className={bg_color}>
        <div className="max-w-7xl mx-auto pb-6 pt-14 md:pb-16 md:pt-24 px-4 sm:px-6 lg:px-8">
            <div className="text-center">
                {subtitle &&
                <h2 className="text-base font-semibold mb-4 text-brand tracking-wide uppercase">{subtitle}</h2>}
                {title &&
                <p className={`mt-1 max-w-2xl ${large?"text-4xl xl:text-6xl sm:text-4xl sm:tracking-tight lg:text-5xl":""} leading-relaxed mx-auto ${title_size} font-extrabold text-gray-900`}>{title}</p>}
                {description && <p className={`max-w-sm mt-2 mx-auto ${description_size} text-gray-500`}>{description}</p>}
            </div>
        </div>
    </div>
}