import * as React from "react"
import Page from "../../components/page";
import Header from "../../components/header";
import {PoliciesContainer} from "../../components/policies/container";

export default function PoliciesPage() {
    return (
        <Page menu>
            <Header title_size="text-4xl xl:text-6xl sm:text-4xl sm:tracking-tight lg:text-5xl" description_size="text-2xl max-w-sm" bg_color='#ffffff' title='Unaty Terms & Policies' />
            <PoliciesContainer>

            </PoliciesContainer>
        </Page>
    )
};